/* The following block can be included in a custom.scss */

$base-color: #8f00ce;
$base-color-light: lighten($base-color, 30%);
$base-dark-color: #46125e;

$red-color: #F51D1E;
$blue-color: #009DFF;
$green-color: #43CD48;
$orange-color: #EBB11B;

/* make the customizations */
$theme-colors: (
    "primary": $base-color,
    "danger": $orange-color,
);

$link-color: $base-color;
$code-color: $blue-color;
$form-check-input-checked-bg-color: $base-color;
$form-check-input-focus-border: $base-color-light;
$form-check-input-focus-box-shadow: 0 0 0 0.25rem lighten($base-color-light, 30%);
$form-range-thumb-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem $base-color-light;
$component-active-bg: $base-color;
$progress-bar-bg: $orange-color;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23d168ff%27/%3e%3c/svg%3e");

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
  }
}


@font-face {
  font-family: 'Solway';
  src: local('Solway'), url(./static/Solway-Regular.ttf) format(truetype);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Solway';
  src: local('Solway'), url(./static/Solway-ExtraBold.ttf) format(truetype);
  font-weight: bold;
  font-style: normal;
}

body, html {
  font-family: 'Solway' !important;
  height: 100%;
  color: $base-dark-color !important;
}

h5 {
  color: $base-color;
  font-weight: bold !important;
  font-size: 1.5rem !important;
}

#root {
  min-height:100%;
  background: #eae5e2;
}

.container {
  background: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.stickycol {
  position: sticky;
  top:1rem;
}

.uploadDrop {
  width:100%;
}

.fileUpload {
  width:100%;
  text-align: center;
  border: 1px solid $blue-color;
  background-color: lighten($blue-color, 46%);
  border-radius: 2rem;
  padding:2rem 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  & svg {
    margin: 3rem 0 1rem;
  }

  &:hover, &:active, &:focus {
    background-color: lighten($blue-color, 48%);
  }
}

.progress {
  position: relative;
}

.progress-bar {
  height:2rem;
}

.progressText {
  position: absolute;
  top:0;
  width:100%;
  left:0;
  height:2rem;
  z-index: 999;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: $base-dark-color;
  padding-top:0.2rem;
}

.leaflet-container {
  height:100%;
  min-height: 500px;
  margin-bottom: 1rem;
}

.filelist {
  height: 10rem;
  overflow-y: scroll;
  display: block;
  border: 1px solid #999;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  scrollbar-gutter: stable;
  max-width: 40rem;
}

.subheader {
  margin-bottom:2rem;
}

.sr-only {
  display: none;
}

.svgContainer {
  border: 2px solid black;
  border-radius: 1rem;
  display: inline-block;
  margin-bottom: 1rem;
  position: relative;
}

.svgContainer .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  opacity: 1;
}

.svgContainer .overlay.hidden{
  opacity: 0;
}

.svgContainer .overlay .content{
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: bolder;
}

.donation-overlay{
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);;
  z-index: 99999 !important;
  width: 100%;
  height: 100%;
}

.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}

#iconHeader {
  max-width: 100%;
}

#traceHeader {
  display: none;
}

@media (min-width: 768px) {

  #iconHeader {
    max-width: 60%;
  }

  #traceHeader {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 11rem;
  }
}